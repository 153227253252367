export default [
  {
    title: 'Coding Experience',
    text: '7+ Languages',
    iconId: 'timerClock',
    extraInfo: 'HTML/(S)CSS, JavaScript, TypeScript, PHP, SQL, C#',
  },
  {
    title: 'Current Specialty',
    text: 'Frontend',
    iconId: 'javascript',
    extraInfo: 'Svelte, ReactJS & VueJS',
  },
  {
    title: 'Recruitment Status',
    text: 'Open To Offers',
    iconId: 'openMailboxWithRaisedFlag',
    extraInfo: 'Email me or message on LinkedIn',
  },
  {
    title: 'Graduated From',
    text: 'Aston University',
    iconId: 'astonUni',
    extraInfo: 'Bsc Digital & Technology Solutions',
  },
  {
    title: 'Achieved Award For',
    text: 'Best Dissertation',
    iconId: 'trophy',
    extraInfo: 'Achieved 91% - highest in year',
  },
  {
    title: 'Current Employer',
    text: 'Dexory',
    iconId: 'dexory',
    extraInfo: 'Since April 2023',
  },
];
