import logoMyTime from './images/mytime.svg';
import logoNca from './images/nca.svg';
import logoBritishGas from './images/britishgas.svg';
import logoDvsa from './images/dvsa.svg';
import logoHomeOffice from './images/homeoffice.svg';
import logoRoyalMail from './images/royalmail.svg';
import logoFormulaOne from './images/formula-1.svg';
import logoGovUk from './images/govuk.svg';
import logoDexory from './images/dexory.svg';

const logoImports: Record<string, string> = {
  logoMyTime,
  logoNca,
  logoBritishGas,
  logoDvsa,
  logoHomeOffice,
  logoRoyalMail,
  logoFormulaOne,
  logoGovUk,
  logoDexory,
};

const logos = (iconName: string) => logoImports[`logo${iconName}`];

export default logos;
