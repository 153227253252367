import styled from 'styled-components';
import { up, down } from 'styled-breakpoints';
import CommonStyled from '../../../common/styled';

const Styled = {
  Header: styled.div`
    display: flex;
    align-items: center;
    background: #190995;
    padding: .7em;

    ${up('mobileLarge')} {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      padding: 30px;
      gap: 20px;
    }
  `,
  SpaceBetween: styled.div`
    display: flex;
    justify-content: space-between;
    flex-grow: 1;

    ${down('tablet')} {
      flex-direction: column;
      justify-content: none;
      padding: 20px .7em;

      ${CommonStyled.DoubleLine} {
        text-align: left;

        &:first-child {
          margin-bottom: .5em;
        }
      }
    }
  `,
  Tech: styled.div`
    background: #f5f5f5;
    padding: .7em;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    display: flex;
    justify-content: center;
    gap: .7em;
    flex-wrap: wrap;
  `,
  IconContainer: styled.button`
    min-height: 24px;
    max-height: 24px;
    min-width: 24px;
    max-width: max-content;
    background: transparent;
    border: none;
    cursor: pointer;
  `,
};

export default Styled;
