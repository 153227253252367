import * as React from 'react';
import { useSpring, animated, config } from 'react-spring';

export default function Logo() {
  const strokeDash = useSpring({
    from: { x: 150 },
    to: { x: 0 },
    config: { ...config.molasses, duration: 3000 },
  });

  return (
    <animated.svg
      width="695"
      viewBox="0 0 695 77"
      fill="white"
      stroke="#190995"
      pathLength="0"
      strokeWidth="5"
      strokeDashoffset={strokeDash.x}
      strokeDasharray="150"
    >
      <g transform="translate(657 6)">
        <path
          d="M 0 64.5 L 38 64.5 M 0 32 L 35 32 M 0 0 L 38 0"
          fill="transparent"
          strokeWidth="5"
          stroke="#190995"
        />
      </g>
      <g transform="translate(584 6.5)">
        <path
          d="M 53 0 L 26.5 0 L 26.5 26.5 M 26.5 31.5 L 26.5 66.5 M 0 0 L 26.5 0"
          fill="transparent"
          strokeWidth="5"
          stroke="#190995"
        />
      </g>
      <g transform="translate(561.5 3)">
        <path
          d="M 0 0 L 0 70"
          fill="transparent"
          strokeWidth="5"
          stroke="#190995"
          strokeLinejoin="round"
        />
      </g>
      <g transform="translate(486.5 3)">
        <path
          d="M 50 0 L 50 70 M 0 35 L 50 35 M 0 0 L 0 70"
          fill="transparent"
          strokeWidth="5"
          stroke="#190995"
        />
      </g>
      <g transform="translate(366 3)">
        <path
          d="M 97 0 L 77 67.5 L 71 67.5 M 45 4.5 L 52 4.5 L 71 67.5 L 77 67.5 M 52 4.5 L 45 4.5 L 26 67.5 L 20 67.5 M 0 0 L 20 67.5 L 26 67.5"
          fill="transparent"
          strokeWidth="5"
          stroke="#190995"
        />
      </g>
      <g transform="translate(281.5 3)">
        <path
          d="M 25 70 L 25 54.25 L 25 38 L 50 0 M 25 38 L 0 0"
          fill="transparent"
          strokeWidth="5"
          stroke="#190995"
        />
      </g>
      <g transform="translate(207 5.5)">
        <path
          d="M 33 42 L 2.5 42 L 2.5 67.5 M 33 42 L 51 67.5 M 33 42 C 49.48 42 49 30 49 30 L 49 12 C 49 12 49.48 0 33 0 M 0 0 L 33 0"
          fill="transparent"
          strokeWidth="5"
          stroke="#190995"
        />
      </g>
      <g transform="translate(135.5 3)">
        <path
          d="M 49 0 L 49 67.5 L 46.5 67.5 M 49 67.5 L 46.5 67.5 L 2.5 2.5 L 0 2.5 L 0 70 L 0 70"
          fill="transparent"
          strokeWidth="5"
          stroke="#190995"
        />
      </g>
      <g transform="translate(75 6)">
        <path
          d="M 0 64.5 L 38 64.5 M 0 32 L 35 32 M 0 0 L 38 0"
          fill="transparent"
          strokeWidth="5"
          stroke="#190995"
          strokeLinejoin="round"
        />
      </g>
      <g transform="translate(2.5 3)">
        <path
          d="M 50 0 L 50 70 M 0 35 L 50 35 M 0 0 L 0 70"
          fill="transparent"
          strokeWidth="5"
          stroke="#190995"
        />
      </g>
    </animated.svg>
  );
}
