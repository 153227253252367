import * as React from 'react';
import CommonStyled from '../common/styled';

export interface IDoubleLineProps {
  title: string;
  text: string;
  right?: boolean;
  dark?: boolean;
  large?: boolean;
}

export default function DoubleLine(props: IDoubleLineProps) {
  const { right = false, title, text, dark, large } = props;

  const overlineColor = dark ? 'rgba(255, 255, 255, 0.8)' : '';
  const bodyColor = dark ? 'rgb(255, 255, 255)' : '';
  const textSize = large ? 24 : 16;

  return (
    <CommonStyled.DoubleLine right={right}>
      <CommonStyled.Overline color={overlineColor}>
        {title}
      </CommonStyled.Overline>
      <CommonStyled.Body color={bodyColor} fontSize={textSize}>
        {text}
      </CommonStyled.Body>
    </CommonStyled.DoubleLine>
  );
}
