import React, { useState } from 'react';
import {
  useTransition,
  animated,
} from 'react-spring';
import Section from '../Section';
import CommonStyled from '../../common/styled';
import statisticsData from '../../constants/statistics';
import Statistic from '../Statistic';

const Statistics = () => {
  const [statistics] = useState<number[]>(Array.from(statisticsData.keys()));

  const statisticTransitions = useTransition(statistics, {
    from: { transform: 'translate3d(0,1000px,0) scale(0)' },
    enter: (item) => ({
      delay: (item + 1) * 500,
      transform: 'translate3d(0,0,0) scale(1)',
    }),
    leave: () => ({
      transform: 'translate3d(0,0,0) scale(0)',
    }),
  });

  return (
    <Section>
      <CommonStyled.Grids.Statistics>
        {statisticTransitions((styles, item) => (
          <animated.div style={styles}>
            <Statistic key={item} id={item} />
          </animated.div>
        ))}
      </CommonStyled.Grids.Statistics>
    </Section>
  );
};

export default Statistics;
