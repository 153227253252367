export default [
  {
    id: 'vue',
    name: 'VueJS',
    description: 'Front-end Framework',
    iconSize: '24px',
  },
  {
    id: 'aws',
    name: 'Amazon Web Services',
    description: 'Cloud Computing Platform',
    iconSize: '20px',
  },
  {
    id: 'firebase',
    name: 'Firebase',
    description: 'Cloud Computing Platform',
    iconSize: '24px',
  },
  {
    id: 'ember',
    name: 'Ember',
    description: 'Front-end Framework',
    iconSize: '30px',
  },
  {
    id: 'polymer',
    name: 'Polymer',
    description: 'Front-end Framework',
    iconSize: '24px',
  },
  {
    id: 'react',
    name: 'ReactJS',
    description: 'Front-end Framework',
    iconSize: '24px',
  },
  {
    id: 'nodeJs',
    name: 'NodeJS',
    description: 'Back-end Framework',
    iconSize: '24px',
  },
  {
    id: 'zf',
    name: 'Zend Framework',
    description: 'PHP Framework',
    iconSize: '14px',
  },
  {
    id: 'angular',
    name: 'Angular',
    description: 'Front-end Framework',
    iconSize: '24px',
  },
  {
    id: 'jenkins',
    name: 'Jenkins',
    description: 'Pipeline tool',
    iconSize: '24px',
  },
  {
    id: 'drupal',
    name: 'Drupal 6/7/8',
    description: 'PHP CMS Framework',
    iconSize: '24px',
  },
];
