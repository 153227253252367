import styled from 'styled-components';
import { up, only } from 'styled-breakpoints';
import { animated } from 'react-spring';

interface IMediaProps {
  background?: string,
}

interface IEqualWidthProps {
  left?: boolean,
  right?: boolean
}

const Styled = {
  Card: styled(animated.div)`
    padding-bottom: 30px;
  `,
  CardLabel: styled.div`
    width: 320px;
    border-radius: 0;
    padding: 10px 0;
    background: #F9F9F9;
    font-weight: 500;
    display: flex;
    margin: -30px auto;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
    flex-flow: row;
    position: relative;
    z-index: 999999;

    ${up('mobileSmall')} {
      border-radius: 8px;
    }
  `,
  EqualWidth: styled.div<IEqualWidthProps>`
    min-width: 90px;
    max-width: 90px;

    ${(props: IEqualWidthProps) => (props.left ? 'padding-left: 10px;' : '')}
    ${(props: IEqualWidthProps) => (props.right ? 'padding-right: 10px;' : '')}
  `,
  TechUsed: styled.div`
    display: flex;
    position: relative;
    z-index: 999999;
    padding: 0 10px;
    background: #eee;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    height: 40px;
    align-items: center;
    margin: -69px auto 0 auto;
    width: max-content;
  `,
  Media: styled.div<IMediaProps>`
    position: relative;
    display: flex;
    padding-top: 75%;
    border: 1px solid rgba(10, 10, 10, 0.1);
    box-shadow: 0px 2px 3px rgba(10, 10, 10, 0.1);
    border-radius: 0;
    background: ${(props: IMediaProps) => (props.background ? props.background : 'white')};

    ${up('mobile')} {
      border-radius: 8px;
    }
  `,
  Image: styled.img`
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    object-fit: scale-down;
    width: 85% !important;
    height: 50%;
    padding-top: 10%;
    margin: 0 auto;

    ${only('desktop')} {
      height: 55%;
    }
  `,
  ButtonContainer: styled.div`
    min-width: 120px;
    max-width: 120px;
    justify-content: center;
    display: flex;
  `,
  IconContainer: styled.div`
    min-height: 24px;
    max-height: 24px;
    display: flex;
    min-width: 24px;
    max-width: max-content;
    justify-content: center;
    align-items: center;
  `,
};

export default Styled;
