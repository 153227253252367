import styled, { createGlobalStyle, css } from 'styled-components';
import { up, only } from 'styled-breakpoints';

const Grid = styled.div`
  display: grid;
  gap: ${(props) => props.theme.gap} ${(props) => props.theme.gap};
`;

interface BodyProps {
  fontWeight?: number;
  fontSize?: number;
  padding?: string;
  margin?: boolean;
}

interface ButtonProps {
  primary?: boolean;
}

const Styled = {
  Global: createGlobalStyle`
    body {
      font-family: ${(props) => props.theme.typography.baseFontFamily};
      background: white;
      font-size: ${(props) => props.theme.typography.baseFontSize};
      margin: 0;
    }
  `,
  Container: styled.div`
    flex-grow: 1;
    margin: 0 auto;
    position: relative;
    width: auto;

    ${up('desktop')} {
      max-width: ${(props) => props.theme.breakpoints.desktop};
    }
    ${up('widescreen')} {
      max-width: ${(props) => props.theme.breakpoints.widescreen};
    }
    ${up('widescreenLarge')} {
      max-width: 1376px;
    }
  `,
  Grids: {
    Projects: styled(Grid)`
      max-width: 100vw;
      grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
      justify-items: center;

      ${up('mobileSmall')} {
        grid-template-columns: repeat(auto-fill, minmax(320px, 400px));
      }
      ${up('mobile')} {
        max-width: calc(100vw - 60px);
      }
      ${only('tabletSmall')} {
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
      }
      ${up('widescreenLarge')} {
        max-width: 1260px;
      }
    `,
    Statistics: styled(Grid)`
      max-width: 250px;
      grid-template-columns: repeat(auto-fill, minmax(250px, 250px));
      justify-items: center;

      ${up('tabletSmall')} {
        max-width: 530px;
      }
      ${up('desktop')} {
        max-width: 810px;
      }
    `,
  },
  Body: styled.p<BodyProps>`
    font-style: normal;
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '500')};
    font-size: ${(props) => (props.fontSize ? props.fontSize : '16')}px;
    line-height: 1.5;
    ${(props) => (!props.margin ? 'margin: 0;' : '')}
    color: ${(props) => (props.color ? props.color : 'rgba(0, 0, 0)')};
    ${(props) => (props.padding ? `padding: ${props.padding};` : '')}
  `,
  Overline: styled.p`
    margin: 5px 0;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: ${(props) => (props.color ? props.color : 'rgba(0, 0, 0, 0.6)')};
  `,
  Title: styled.h3`
    text-transform: uppercase;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
  `,
  DoubleLine: styled.div<{ right: boolean }>`
    text-align: ${(props) => (props.right ? 'right' : 'left')};
  `,
  Button: styled.button<ButtonProps>`
    cursor: pointer;
    font-family: Montserrat;
    font-weight: 600;
    max-height: 3em;
    font-size: 0.75em;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.7);
    background: white;
    border: 1px solid #d2d6dc;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    align-items: center;
    align-self: center;
    padding: 8px 12px;
    display: inline-flex;
    border-radius: 0.5em;
    line-height: 1.5em;

    ${(props: any) =>
      props.primary &&
      css`
        color: white;
        background: #190995;

        &:hover,
        &:focus {
          color: rgba(255, 255, 255, 0.8);
        }
      `}

    svg {
      height: 1em;
      width: 1.3em;
      font-size: 20px;
      margin-right: 10px;
      display: block;
    }

    &:hover,
    &:focus {
      color: white;
      background: #190995;
    }
  `,
  Chip: styled.div<ButtonProps>`
    font-family: Montserrat;
    font-weight: 600;
    max-height: 3em;
    font-size: 0.75em;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.7);
    background: white;
    border: 1px solid #d2d6dc;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    align-items: center;
    align-self: center;
    padding: 0.7em 1em;
    display: inline-flex;
    border-radius: 2em;
    line-height: 1.5em;
    cursor: help;

    svg {
      height: 1em;
      font-size: 20px;
      width: 1.3em;
      margin-right: 10px;
      display: block;
    }
  `,
};

export default Styled;
