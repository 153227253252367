import styled from 'styled-components';
import { up } from 'styled-breakpoints';

const Styled = {
  Wrapper: styled.div`
    display: flex;
    gap: .7em;
    justify-content: space-evenly;
    flex-wrap: wrap;

    ${up('tabletSmall')} {
      flex-wrap: nowrap;
      padding: 0 1em;
    }

    ${up('tablet')} {
      padding: 0 30px;
      gap: 30px;
    }
  `,
  Summary: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 .7em;
    gap: .7em;

    p {
      text-align: center;
      max-width: 514px;
    }

    ${up('tabletSmall')} {
      gap: 0;

      p {
        text-align: left;
      }
    }

    ${up('tablet')} {
      padding: 1em 0;
    }
  `,
  ButtonGroup: styled.div`
    display: flex;
    justify-content: center;
    gap: .7em;
    flex-wrap: wrap;

    ${up('tabletSmall')} {
      justify-content: start;
    }
  `,
  Image: styled.img`
    max-height: 100px;

    ${up('tablet')} {
      max-height: 130px;
    }
  `,
};

export default Styled;
