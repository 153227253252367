import styled from 'styled-components';
import { animated } from 'react-spring';

const Styled = {
  Section: styled(animated.div)`
    padding: 15px 0;
  `,
  Centered: styled.div`
    display: flex;
    justify-content: center;
  `,
};

export default Styled;
