import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import Styled from './styled';
import DoubleLine from '../DoubleLine';
import statistics from '../../constants/statistics';
import icons from '../../assets/icons';

interface Props {
  id: number;
}

const Statistic = (props: Props) => {
  const { id } = props;
  const statistic = statistics[id];
  const { title, text, iconId, extraInfo } = statistic;

  const calc = (x: number, y: number) => [
    -(y - window.innerHeight / 2) / 20,
    (x - window.innerWidth / 2) / 20,
    1.1,
  ];
  const trans = (x: number, y: number, s: number) =>
    `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;
  const [style, setStyle] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40 },
  }));

  const [flipped, setFlipped] = useState(false);
  const { transform, opacity } = useSpring({
    opacity: flipped ? 1 : 0,
    transform: `perspective(600px) rotateX(${flipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });

  return (
    <animated.div
      tabIndex={0}
      onBlur={() => {
        setStyle({ xys: [0, 0, 1] });
        setFlipped(false);
      }}
      onFocus={() => {
        setFlipped((state) => !state);
      }}
      onMouseMove={({ clientX: x, clientY: y }) =>
        setStyle({ xys: calc(x, y) })
      }
      onMouseLeave={() => {
        setStyle({ xys: [0, 0, 1] });
        setFlipped(false);
      }}
      style={{
        outline: 'none',
        transform: style.xys.to(trans),
      }}
    >
      <Styled.Statistic
        onClick={() => {
          setFlipped((state) => !state);
        }}
        style={{
          display: flipped ? 'none' : 'flex',
          opacity: opacity.to((o) => 1 - o),
          transform,
        }}
      >
        <Styled.Icon icon={icons(iconId)} key={iconId} />
        <DoubleLine title={title} text={text} />
      </Styled.Statistic>
      <Styled.Statistic
        onClick={() => {
          setFlipped((state) => !state);
        }}
        style={{
          background: '#190995',
          display: flipped ? 'flex' : 'none',
          opacity,
          transform: transform.to((t) => `${t} rotateX(180deg)`),
        }}
      >
        <DoubleLine dark title={text} text={extraInfo} />
      </Styled.Statistic>
    </animated.div>
  );
};

export default Statistic;
