import React, { useState, useEffect } from 'react';
import {
  useTransition,
} from 'react-spring';
import usePortal from 'react-useportal';
import Styled from './styled';

interface ModalProps {
  modalOpen: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
}

const Modal = (props: ModalProps) => {
  const { modalOpen, children, onClose } = props;
  const [open, setOpen] = useState(modalOpen);
  const [visible, setVisible] = useState(false);
  const { Portal } = usePortal({ closeOnOutsideClick: false });

  const transition = useTransition(open, {
    from: {
      transform: 'translate3d(0,1000px,0)',
    },
    enter: {
      opacity: open ? 1 : 0,
      transform: `translate3d(0,${open ? 0 : 1000}px,0)`,
    },
    leave: {
      opacity: open ? 1 : 0,
      transform: `translate3d(0,${open ? 0 : 1000}px,0)`,
    },
    config: { mass: 5, tension: 500, friction: 80 },
    onRest: () => {
      if (open) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    },
  });

  useEffect(() => {
    setOpen(modalOpen);
  }, [modalOpen]);

  useEffect(() => {
    if (!open && !visible && onClose) {
      onClose();
    }
  }, [open, visible, onClose]);

  return (
    <Portal>
      {transition(({ opacity, transform }) => (
        <Styled.Overlay
          style={{ opacity }}
          onClick={() => setOpen(false)}
        >
          <Styled.Content
            style={{ transform }}
            onClick={(e) => e.stopPropagation()}
          >
            {children}
          </Styled.Content>
        </Styled.Overlay>
      ))}
    </Portal>
  );
};

Modal.defaultProps = {
  children: null,
  onClose: null,
};

export default Modal;
