import React from 'react';
import { Icon } from '@iconify/react';
import Me from '../../assets/images/Me.png';
import icons from '../../assets/icons';
import Section from '../Section';
import CommonStyled from '../../common/styled';
import Styled from './styled';

const Introduction = () => (
  <Section fadeIn>
    <Styled.Wrapper>
      <Styled.Image src={Me} alt="" />
      <Styled.Summary>
        <CommonStyled.Body fontWeight={400}>
          I am a passionate and motivated frontend developer with over 10 years
          of experience in website and application development
        </CommonStyled.Body>
        <Styled.ButtonGroup>
          <CommonStyled.Button
            onClick={() => window.open('mailto:Contact@henrywhite.dev')}
          >
            <Icon icon={icons('envelope')} />
            Contact@henrywhite.dev
          </CommonStyled.Button>
          <CommonStyled.Button
            onClick={() =>
              window.open(
                'https://www.linkedin.com/in/hwhitedev?original_referer=henrywhite.dev'
              )
            }
          >
            <Icon icon={icons('linkedInDesktop')} />
            hwhitedev
          </CommonStyled.Button>
        </Styled.ButtonGroup>
      </Styled.Summary>
    </Styled.Wrapper>
  </Section>
);

export default Introduction;
