/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Icon } from '@iconify/react';
import { Popup } from 'semantic-ui-react';
import Styled from './styled';
import CommonStyled from '../../common/styled';
import DoubleLine from '../DoubleLine';
import icons from '../../assets/icons';
import logos from '../../assets/logos';
import allTech from '../../constants/tech';
import { IDProps } from '../../common/types';
import ProjectExpanded from './Expanded';

const Project = (props: any) => {
  const [expanded, setExpanded] = useState(false);

  const { item } = props;
  const { name, background, techUsed, devFocus, completed, dates } = item;

  const timestampToYear = (timestamps: any[]) =>
    timestamps.map((timestamp) => dayjs.unix(timestamp.seconds).format('YYYY'));

  return (
    <>
      {expanded && (
        <ProjectExpanded
          dates={dates}
          longName={item.longName}
          summary={item.summary}
          techUsed={techUsed}
          onClose={() => setExpanded(false)}
        />
      )}
      <Styled.Card>
        <Styled.Media background={background}>
          <Styled.Image src={logos(name)} alt={name} />
          <Styled.TechUsed>
            <CommonStyled.Overline>Tech Used</CommonStyled.Overline>
            {techUsed.map((tech: IDProps) => {
              const techItem = allTech.filter((i) => i.id === tech.id)[0];

              return (
                <Popup
                  content={
                    <CommonStyled.Overline color="white">
                      {techItem.description}
                    </CommonStyled.Overline>
                  }
                  inverted
                  offset="5px,10px"
                  position="top center"
                  key={techItem.name}
                  header={
                    <CommonStyled.Overline color="rgba(255, 255, 255, 0.8)">
                      {techItem.name}
                    </CommonStyled.Overline>
                  }
                  trigger={
                    <Styled.IconContainer>
                      <Icon
                        icon={icons(tech.id)}
                        key={tech.id}
                        style={{
                          fontSize: techItem.iconSize,
                          marginLeft: '10px',
                        }}
                      />
                    </Styled.IconContainer>
                  }
                />
              );
            })}
          </Styled.TechUsed>
        </Styled.Media>
        <Styled.CardLabel>
          <Styled.EqualWidth left>
            <DoubleLine title="Dev Focus" text={devFocus} />
          </Styled.EqualWidth>
          <Styled.ButtonContainer>
            <CommonStyled.Button onClick={() => setExpanded(true)}>
              Read More
            </CommonStyled.Button>
          </Styled.ButtonContainer>
          <Styled.EqualWidth right>
            <DoubleLine
              title={completed ? 'Completed' : 'Started'}
              text={timestampToYear(dates).join('-')}
              right
            />
          </Styled.EqualWidth>
        </Styled.CardLabel>
      </Styled.Card>
    </>
  );
};

export default Project;
