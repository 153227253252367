import React from 'react';
import { useSpring } from 'react-spring';
import Styled from './styled';
import Logo from '../Logo';

interface IHeaderProps {
  subTitle: string
}

const Header = (props: IHeaderProps) => {
  const { subTitle } = props;

  const opacity = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  return (
    <Styled.Header>
      <Logo />
      <Styled.SubTitle style={opacity}>{subTitle}</Styled.SubTitle>
    </Styled.Header>
  );
};

export default Header;
