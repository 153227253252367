import React, { RefObject } from 'react';
import {
  useSpring,
  SpringHandle,
  UnknownProps,
} from 'react-spring';
import Styled from './styled';
import CommonStyled from '../../common/styled';

type Props = {
  title?: String,
  children?: React.ReactNode,
  fadeIn?: boolean,
  ref?: RefObject<SpringHandle<UnknownProps>>,
};

const Section = (props: Props) => {
  const {
    title,
    children,
    ref,
    fadeIn,
  } = props;

  const spring = useSpring({
    ref,
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: {
      duration: 1500,
    },
  });

  return (
    <Styled.Section style={fadeIn ? spring : {}}>
      {title && (
        <CommonStyled.Title>
          {title}
        </CommonStyled.Title>
      )}
      <Styled.Centered>
        {children}
      </Styled.Centered>
    </Styled.Section>
  );
};

Section.defaultProps = {
  children: null,
};

export default Section;
