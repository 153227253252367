import envelope from '@iconify/icons-emojione/envelope';
import timerClock from '@iconify/icons-emojione/timer-clock';
import javascript from '@iconify/icons-logos/javascript';
import openMailboxWithRaisedFlag from '@iconify/icons-emojione/open-mailbox-with-raised-flag';
import trophy from '@iconify/icons-emojione/trophy';
import vue from '@iconify/icons-logos/vue';
import react from '@iconify/icons-logos/react';
import zf from '@iconify/icons-logos/zend-framework';
import ember from '@iconify/icons-logos/ember-tomster';
import polymer from '@iconify/icons-logos/polymer';
import firebase from '@iconify/icons-logos/firebase';
import aws from '@iconify/icons-logos/aws';
import angular from '@iconify/icons-logos/angular-icon';
import drupal from '@iconify/icons-logos/drupal-icon';
import jenkins from '@iconify/icons-logos/jenkins';
import nodeJs from '@iconify/icons-logos/nodejs-icon';
import linkedInDesktop from './icons/linkedin';
import astonUni from './icons/aston';
import capgemini from './icons/capgemini';
import dexory from './icons/dexory';
import back from './icons/back';

const iconImports: Record<string, any> = {
  envelope,
  linkedInDesktop,
  timerClock,
  javascript,
  openMailboxWithRaisedFlag,
  astonUni,
  trophy,
  capgemini,
  dexory,
  vue,
  react,
  zf,
  ember,
  polymer,
  firebase,
  aws,
  angular,
  drupal,
  jenkins,
  nodeJs,
  back,
};

const icons = (iconName: string) => iconImports[iconName];

export default icons;
