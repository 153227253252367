import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { animated } from 'react-spring';

const Styled = {
  Overlay: styled(animated.div)`
    ${(props) => (props.hidden ? 'display: none;' : '')}
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(255, 255, 255, 0.75);
  `,
  Content: styled(animated.div)`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    background: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);

    ${up('mobileLarge')} {
      height: auto;
      margin: 2em auto;
      max-width: 85vw;
      max-height: max-content;
      border-radius: 6px;
    }
  `,
};

export default Styled;
