import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { animated } from 'react-spring';

const Styled = {
  Header: styled.header`
    margin: 0 auto;
    padding: 30px 15px 5px 15px;
    
    svg {
      width: 100%;
    }

    ${up('tabletSmall')} {
      width: 568px;
    }

    ${up('tablet')} {
      width: 695px;
    }
  `,
  SubTitle: styled(animated.h2)`
    margin-top: 10px;
    margin-bottom: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.19em;
    text-transform: uppercase;
    color: #767676;

    ${up('mobileLarge')} {
      font-size: 20px;
    }
  `,
};

export default Styled;
