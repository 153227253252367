import React from 'react';
import {
  useTransition,
  animated,
} from 'react-spring';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import Section from '../Section';
import CommonStyled from '../../common/styled';
import Project from '../Project';

const Projects = () => {
  const [projects, loading, error] = useCollectionData(
    firebase.firestore().collection('projects').orderBy('id'),
  );

  const projectTransitions = useTransition(projects, {
    from: { transform: 'translate3d(0,1000px,0) scale(0)' },
    enter: (item: any) => ({
      delay: item.id * 500,
      transform: 'translate3d(0,0,0) scale(1)',
    }),
    leave: () => ({
      transform: 'translate3d(0,1000px,0) scale(0)',
    }),
  });

  return (
    <Section>
      {error && (
      <strong>
        Error:
        {' '}
        {JSON.stringify(error)}
      </strong>
      )}
      {loading && <span>Collection: Loading...</span>}
      <CommonStyled.Grids.Projects>
        {projectTransitions((styles, item: any) => (
          <animated.div key={item.id} style={{ ...styles, justifySelf: 'stretch' }}>
            <Project item={item} />
          </animated.div>
        ))}
      </CommonStyled.Grids.Projects>
    </Section>
  );
};

export default Projects;
