import { Icon } from '@iconify/react';
import styled from 'styled-components';
import { animated } from 'react-spring';

const Styled = {
  Statistic: styled(animated.div)`
    width: 220px;
    height: 45px;
    padding: 20px 15px;
    background: #f5f5f5;
    border-radius: 8px;
    display: flex;
    align-items: center;

    &:hover,
    &:focus {
      cursor: pointer;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    }
  `,
  Icon: styled(Icon)`
    font-size: 50px; 
    margin-right: 15px;
    width: 1em !important;
  `,
};

export default Styled;
