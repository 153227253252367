import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Icon } from '@iconify/react';
import { Popup } from 'semantic-ui-react';
import icons from '../../../assets/icons';
import allTech from '../../../constants/tech';
import DoubleLine from '../../DoubleLine';
import Styled from './styled';
import CommonStyled from '../../../common/styled';
import Modal from '../../Modal';

interface Props {
  onClose: () => void;
  dates: number[];
  longName: string;
  summary: string;
  techUsed: any[];
}

const ProjectExpanded = (props: Props) => {
  const [modalOpen, setModalOpen] = useState(true);

  const {
    dates,
    longName,
    summary,
    techUsed,
    onClose,
  } = props;

  const timestampToMonthYear = (timestamps: any[]) => timestamps.map((timestamp) => dayjs.unix(timestamp.seconds).format('MMMM YYYY'));

  return (
    <Modal modalOpen={modalOpen} onClose={() => onClose()}>
      <Styled.Header>
        <Styled.IconContainer
          onClick={() => setModalOpen(false)}
        >
          <Icon
            icon={icons('back')}
            width={24}
            height="24"
          />
        </Styled.IconContainer>
        <Styled.SpaceBetween>
          <DoubleLine
            title="project"
            text={longName}
            large
            dark
          />
          <DoubleLine
            title="when"
            text={timestampToMonthYear(dates).join(' - ')}
            large
            dark
            right
          />
        </Styled.SpaceBetween>
      </Styled.Header>
      <div style={{ padding: '15px 30px', flexGrow: 1 }}>
        {summary.split('\n').map((item) => <CommonStyled.Body margin>{item}</CommonStyled.Body>)}
      </div>
      <Styled.Tech>
        {techUsed.map((tech) => {
          const techItem = allTech.filter((item) => item.id === tech.id)[0];

          return (
            <Popup
              content={(
                <CommonStyled.Overline color="white">
                  {techItem.description}
                </CommonStyled.Overline>
                )}
              inverted
              position="top center"
              key={techItem.name}
              trigger={(
                <CommonStyled.Chip>
                  <Icon
                    icon={icons(techItem.id)}
                  />
                  {`${techItem.name}`}
                </CommonStyled.Chip>
                )}
            />
          );
        })}
      </Styled.Tech>
    </Modal>
  );
};

export default ProjectExpanded;
