import React from 'react';
import Header from '../components/Header';
import Introduction from '../components/Introduction';
import Statistics from '../components/Statistics';
import Projects from '../components/Projects';
import CommonStyled from '../common/styled';

const Home: React.FC = () => (
  <CommonStyled.Container>
    <Header subTitle="Senior Frontend Engineer" />
    <Introduction />
    <Statistics />
    <Projects />
  </CommonStyled.Container>
);

export default Home;
